'use strict';
module.exports.documentationToggle = function() {
	const documentationSectionIsVisible = document.getElementById('documentation-container') || false;
	if (documentationSectionIsVisible) {
		const TRIGGER = document.getElementById('documentation-trigger');
		const CONTENT = document.getElementById('documentation-content');

		TRIGGER.addEventListener('click', function() {
			const LESS_TEXT = 'Less details';
			TRIGGER.innerText = TRIGGER.innerText === LESS_TEXT ? 'More details' : LESS_TEXT;
			CONTENT.classList.toggle('is-hidden');
		});
	}
	return;
};